import React, {useEffect, useState} from 'react';
import { Button, Input } from 'reactstrap';
import TabellaRicerca from './tabellaricerca';

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_TABELLA = 2;
const STATO_MODIFICA = 3;
const STATO_INSERISCI = 4;
const STATO_ELIMINA = 5;
const STATO_ERRORE = 6;
const STATO_ATTESA = 7;

// nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
const colonne = [
    {
        etichetta: 'ID',
        accessor: 'ID'
      },
      {
        etichetta: 'Ragione Sociale',
        accessor: 'RAGIONE_SOCIALE'
      },
      {
          etichetta: 'Cons.',
          accessor: 'CONSEGNATO'
      },
      {
          etichetta: 'Num.Ord.',
          accessor: 'NUMEROORDINE'
      },
      {
          etichetta: 'Tipo',
          accessor: 'TIPOORDINE'
      },    
      {
        etichetta: 'Data Ord.',
        accessor: 'DATAORDINE'
      },
      {
          etichetta: 'Cod. Articolo',
          accessor: 'CODICE_ARTICOLO'
      },  
      {
        etichetta: 'Inizio Serv.',
        accessor: 'DATA_INIZIO_SERVIZIO'
      },
      {
        etichetta: 'Fine Serv.',
        accessor: 'DATA_FINE_SERVIZIO'
      },
      {
          etichetta: 'Iscritti',
          accessor: 'LIMITE_ISCRITTI'
      },
      {
          etichetta: 'MB',
          accessor: 'LIMITE_MB'
      },
      {
          etichetta: 'Durata',
          accessor: 'DURATA_VALORE'
      },
      {
          etichetta: '(udm)',
          accessor: 'DURATA_UNITA_MISURA'
      },
      {
          etichetta: 'Lordo',
          accessor: 'IMPORTO_LORDO'
      },
      {
          etichetta: 'IVA',
          accessor: 'IVA'
      },
      {
        etichetta: 'Netto',
        accessor: 'IMPORTO_NETTO'
      },
      {
        etichetta: 'Pagato',
        accessor: 'IMPORTO_PAGATO'
      },
      {
        etichetta: 'Pagamento',
        accessor: 'METODO_PAGAMENTO'
      }
  ];

function Ordini(props) {    
    const [stato, setStato] = useState(STATO_INDEFINITO);
    const [elencoArticoli, setElencoArticoli] = useState([]);
    const [elencoClienti, setElencoClienti] = useState([]);
    const [datiTabella, setDatiTabella] = useState([]);
    const [datiRecord, setDatiRecord] = useState([]);
    const [campoConferma, setCampoConferma] = useState("");

    useEffect(() => {
        setStato(STATO_RICERCA_DATI);
    },[])

    useEffect(() => {
        if (stato === STATO_RICERCA_DATI) scaricaDatiTabella();               
    },[stato])  

    function leggiRecordPerModifica(id) {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"ordini_leggi_record",ID:id,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    // trasformo la stringa che contiene i codici articolo separati da punto e virgola in un array
                    valoreDiRitorno.record[0].CODICE_ARTICOLO = valoreDiRitorno.record[0].CODICE_ARTICOLO.split(';').map((value) => value.trim());

                    setDatiRecord(valoreDiRitorno.record[0]);
                    setStato(STATO_MODIFICA);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );                
    }

    function eliminaRecord(id) {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"ordini_canc",ID:id,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    scaricaDatiTabella();
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );        
    }

    function scaricaDatiTabella() {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elenco_ordini",emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiTabella(valoreDiRitorno.risultatoRitorno);                    

                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({op:"elenco_clienti",emak:props.emak,sessionId:props.sessionId})
                    };
            
                    fetch(props.serverURL, requestOptions)
                        .then(risposta => risposta.json())
                        .then(
                        (valoreDiRitorno) => {
                            if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                            if(valoreDiRitorno.risultatoOperazione!==1){
                                alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                                setStato(STATO_ERRORE);
                                return;
                            }
                            if(valoreDiRitorno.risultatoOperazione===1){
                                setElencoClienti(valoreDiRitorno.risultatoRitorno);

                                const requestOptions = {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify({op:"elenco_articoli",emak:props.emak,sessionId:props.sessionId})
                                };
                        
                                fetch(props.serverURL, requestOptions)
                                    .then(risposta => risposta.json())
                                    .then(
                                    (valoreDiRitorno) => {
                                        if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                                        if(valoreDiRitorno.risultatoOperazione!==1){
                                            alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                                            setStato(STATO_ERRORE);
                                            return;
                                        }
                                        if(valoreDiRitorno.risultatoOperazione===1){
                                            setElencoArticoli(valoreDiRitorno.risultatoRitorno);
                                            setStato(STATO_TABELLA);
                                        }
                                    },
                                        (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
                                    );
                            }
                        },
                            (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
                        );
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    function memorizza() {
        if (datiRecord.TIPOORDINE==undefined || datiRecord.TIPOORDINE=="") {
            alert("Seleziona il tipo di ordine");
            return;
        }
        if (datiRecord.IDCLIENTE==undefined || datiRecord.IDCLIENTE=="") {
            alert("Seleziona il cliente");
            return;
        }
        if (datiRecord.CODICE_ARTICOLO==undefined || datiRecord.CODICE_ARTICOLO=="") {
            alert("Seleziona l'articolo");
            return;
        }
        if (datiRecord.TIPOORDINE!="aggiuntivo" && (datiRecord.DURATA_UNITA_MISURA==undefined || datiRecord.DURATA_UNITA_MISURA=="")) {
            alert("Seleziona l'unità di misura della durata");
            return;
        }
        // trasformo datiRecord.CODICE_ARTICOLO, che è un array con le opzioni selezionate, in una stringa con le opzioni selezionate separate da punto e virgola
        datiRecord.CODICE_ARTICOLO = datiRecord.CODICE_ARTICOLO.join(';');

        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:stato===STATO_INSERISCI?"ordini_ins":"ordini_mod",record:datiRecord,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    scaricaDatiTabella();
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    if (stato===STATO_ERRORE) return(<div></div>)
    if (stato===STATO_ATTESA) return(<div>attendi...</div>)

    if (stato===STATO_TABELLA) {
        return (            
            <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                <div>
                    <div className="titolo">Ordini: {datiTabella.length}<br/>
                    <Button className="bottoneStandard" onClick={() => { setDatiRecord([]); setStato(STATO_INSERISCI)}}>Nuovo</Button>
                    </div>
                    <div className="spazio1"/>
                </div>
                {datiTabella !== undefined && datiTabella !== "" ?
                    <TabellaRicerca 
                        columns={colonne} 
                        data={datiTabella} 
                        nascondiColonne={[0]} 
                        campoChiave={0} 
                        campoOrdinamento={5}
                        coloriPersonalizzati={[
                            {"campo":"2","condizione":"==","valore":"1","carattereRigaPari":"#000000","sfondoRigaPari":"#D0FFD0","carattereRigaDispari":" #000000","sfondoRigaDispari":"#C5F5C5"},
                          ]}
                        callbackSelezionaElemento={leggiRecordPerModifica}
                        callbackEliminaElemento={(id) => {
                            var r=[];
                            for (var n=0; n<datiTabella.length; n++) {
                                if (datiTabella[n].ID===id) {
                                    r=datiTabella[n];
                                    break;
                                }
                            }
                            setDatiRecord(r);
                            setStato(STATO_ELIMINA);
                        }} 
                    />
                : 
                    <div>Errore ricerca dati</div>
                }
            </div>
        )      
    }

    if (stato===STATO_ELIMINA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div>
                        <div className="titolo">Elimina Ordine</div>
                    </div>
                    <div style={{backgroundColor:"#FF0000", color: "#FFFF00", padding:"10px"}}>
                        Confermi eliminazione dell'ordine {datiRecord.ID} di {datiRecord.RAGIONE_SOCIALE} del {datiRecord.DATAORDINE})?                    
                        <br/><br/>
                        Scrivi ok qui sotto:
                        <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="text" onInput={(e)=>{setCampoConferma(e.target.value)}}/></div>
                    </div>
                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="bottoneAllarme" onClick={() => { if (campoConferma==="ok") {setCampoConferma(""); eliminaRecord(datiRecord.ID)}}}>Conferma eliminazione</Button>
                    &nbsp;
                    <Button className="bottoneStandard" onClick={() => { scaricaDatiTabella()}}>Annulla</Button>
                </div>
            </div> 
        )      
    }                    

    if (stato===STATO_INSERISCI || stato==STATO_MODIFICA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div>
                        <div className="titolo">{stato===STATO_INSERISCI?"Nuovo Ordine":"Modifica Ordine "+datiRecord.NUMEROORDINE}</div>                        
                    </div>
                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Cliente</div>
                            <select value={datiRecord.IDCLIENTE} className="inputSelect" onChange={(e) => {var r={...datiRecord}; r.IDCLIENTE=e.target.value; setDatiRecord(r);}}>
                                <option value=""></option>
                                {elencoClienti.map((cliente,indice)=>
                                    <option value={cliente.ID}>{cliente.RAGIONE_SOCIALE}</option>)
                                }
                            </select>

                            <br/><br/>

                            <div className="etichetta">Data ordine (AAAA-MM-GG con eventuale hh:mm:ss opzionale)</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.DATAORDINE}
                            onInput={(e)=>{var r={...datiRecord}; r.DATAORDINE=e.target.value; setDatiRecord(r);}}/> 

                            <br/><br/>

                            <div className="etichetta">Tipo di ordine</div>
                            <select className='inputSelect' value={datiRecord.TIPOORDINE}
                                onChange={(e)=>{var r={...datiRecord}; r.TIPOORDINE=e.target.value; setDatiRecord(r);}}
                            >
                                <option value=''></option>
                                <option value='nuovo'>nuovo</option>
                                <option value='rinnovo'>rinnovo</option>
                                <option value='aggiuntivo'>aggiuntivo</option>
                            </select>

                        </div>
                        <div className="colonna">
                            <div className="etichetta">Articoli</div>
                            <select multiple={true} size={10} value={datiRecord.CODICE_ARTICOLO} className="inputSelectMultipla" onChange={(e) => {var r={...datiRecord}; r.CODICE_ARTICOLO=Array.from(e.target.selectedOptions, (option) => option.value); setDatiRecord(r);}}>
                                <option value=""></option>
                                {elencoArticoli.map((cliente,indice)=>
                                    <option value={cliente.CODICE_ARTICOLO}>{cliente.CODICE_ARTICOLO}</option>)
                                }
                            </select>
                        </div>
                    </div>

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Importo Lordo</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.IMPORTO_LORDO}
                            onInput={(e)=>{var r={...datiRecord}; r.IMPORTO_LORDO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">IVA</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.IVA}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.IVA=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Importo Netto</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.IMPORTO_NETTO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.IMPORTO_NETTO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div>                    

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Limite Iscritti</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.LIMITE_ISCRITTI}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.LIMITE_ISCRITTI=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Limite MB</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.LIMITE_MB}
                            onInput={(e)=>{var r={...datiRecord}; r.LIMITE_MB=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Durata (se Tipo non Aggiuntivo)</div>

                            <div style={{display:"flex", flexDirection:'row'}}>
                                <div style={{flex:1, paddingRight:20}}>                                
                                    <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.DURATA_VALORE}
                                    onInput={(e)=>{var r={...datiRecord}; r.DURATA_VALORE=e.target.value; setDatiRecord(r);}}/> 
                                </div>
                                <div style={{flex:1}}>
                                    <select className='inputSelect' value={datiRecord.DURATA_UNITA_MISURA}
                                        onChange={(e)=>{var r={...datiRecord}; r.DURATA_UNITA_MISURA=e.target.value; setDatiRecord(r);}}
                                    >
                                        <option value=""></option>
                                        <option value='giorni'>giorni</option>
                                        <option value='mesi'>mesi</option>
                                        <option value='anni'>anni</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div> 

                    { stato==STATO_MODIFICA ?
                        <>
                            <div className="riga" style={{fontWeight:'bold', backgroundColor:"#D0E0F0", paddingLeft:10, paddingTop:5, paddingBottom:5}}>  
                                Dati da inserire solo dopo aver ricevuto il pagamento:
                            </div>

                            <div className="riga">  
                                <div className="colonna">
                                    <div className="etichetta">Consegnato</div>
                                    <select className='inputSelect' value={datiRecord.CONSEGNATO}
                                        onChange={(e)=>{var r={...datiRecord}; r.CONSEGNATO=e.target.value; setDatiRecord(r);}}
                                        >
                                            <option value='0'>NO</option>
                                            <option value='1'>SI</option>
                                        </select>
                                </div> 

                                <div className="colonna">
                                    <div className="etichetta">Data inizio servizio (AAAA-MM-GG)</div>
                                    <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.DATA_INIZIO_SERVIZIO}
                                    placeholder="" onInput={(e)=>{var r={...datiRecord}; r.DATA_INIZIO_SERVIZIO=e.target.value; setDatiRecord(r);}}/> 
                                </div>
                                <div className="colonna">
                                    <div className="etichetta">Data fine servizio (AAAA-MM-GG)</div>
                                    <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.DATA_FINE_SERVIZIO}
                                    placeholder="" onInput={(e)=>{var r={...datiRecord}; r.DATA_FINE_SERVIZIO=e.target.value; setDatiRecord(r);}}/> 
                                </div>
                            </div> 

                            <div className="riga">  
                                <div className="colonna">
                                    <div className="etichetta">Importo Pagato</div>
                                    <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.IMPORTO_PAGATO}
                                    placeholder="" onInput={(e)=>{var r={...datiRecord}; r.IMPORTO_PAGATO=e.target.value; setDatiRecord(r);}}/> 
                                </div>
                                <div className="colonna">
                                    <div className="etichetta">Data pagamento (AAAA-MM-GG)</div>
                                    <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.DATA_PAGAMENTO}
                                    onInput={(e)=>{var r={...datiRecord}; r.DATA_PAGAMENTO=e.target.value; setDatiRecord(r);}}/> 
                                </div>
                                <div className="colonna">
                                    <div className="etichetta">Metodo Pagamento</div>
                                    <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.METODO_PAGAMENTO}
                                    placeholder="" onInput={(e)=>{var r={...datiRecord}; r.METODO_PAGAMENTO=e.target.value; setDatiRecord(r);}}/> 
                                </div>
                            </div>                    
                        </>     
                        :
                        <></>   
                    }                    

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Note</div>
                            <textarea rows={10} className="inputStandard" value={datiRecord.NOTE}
                            placeholder="" onChange={(e)=>{var r={...datiRecord}; r.NOTE=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div>

                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="bottoneStandard" onClick={() => { memorizza()}}>Memorizza</Button>
                    &nbsp;
                    <Button className="bottoneStandard" onClick={() => { scaricaDatiTabella()}}>Torna all'elenco</Button>
                </div>
            </div> 
        )      
    }

    return (
        <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain, color:"#202020"}}>
            ORDINI - stato errato: {stato}
        </div>
    )
}

export default Ordini;