import React, {useEffect, useState} from 'react';
import { Button, Input } from 'reactstrap';
import TabellaRicerca from './tabellaricerca';

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_TABELLA = 2;
const STATO_MODIFICA = 3;
const STATO_INSERISCI = 4;
const STATO_ELIMINA = 5;
const STATO_ERRORE = 6;
const STATO_ATTESA = 7;
const STATO_ATTESA2 = 8;

// nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
const colonne = [
    {
      etichetta: 'ID',
      accessor: 'ID'
    },
    {
      etichetta: 'Ragione Sociale',
      accessor: 'RAGIONE_SOCIALE'
    },
    {
      etichetta: 'Email',
      accessor: 'EMAIL'
    },
    {
        etichetta: 'Telefono',
        accessor: 'TELEFONO'
    },  
    {
      etichetta: 'P.IVA',
      accessor: 'PIVA'
    },
    {
      etichetta: 'Cod. Fiscale',
      accessor: 'CODFISCALE'
    },
    {
        etichetta: 'Indirizzo',
        accessor: 'INDIRIZZO'
    },
    {
        etichetta: 'CAP',
        accessor: 'CAP'
    },
    {
      etichetta: 'Città',
      accessor: 'CITTA'
    },
    {
      etichetta: 'Stato',
      accessor: 'STATO'
    },
    {
      etichetta: 'Cod.Dest.',
      accessor: 'CODICE_DESTINATARIO_FATT_ELETTRONICA'
    }
  ];

function Clienti(props) {    
    const [stato, setStato] = useState(STATO_INDEFINITO);
    const [datiTabella, setDatiTabella] = useState([]);
    const [datiRecord, setDatiRecord] = useState([]);
    const [campoConferma, setCampoConferma] = useState("");

    useEffect(() => {
        setStato(STATO_RICERCA_DATI);
    },[])
    
    useEffect(() => {
        if (stato === STATO_RICERCA_DATI) scaricaDatiTabella();               
    },[stato])  

    function leggiRecordPerModifica(id) {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"clienti_leggi_record",ID:id,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiRecord(valoreDiRitorno.record[0]);
                    setStato(STATO_MODIFICA);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );                
    }

    function eliminaRecord(id) {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"clienti_canc",ID:id,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    scaricaDatiTabella();
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );        
    }

    function scaricaDatiTabella() {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elenco_clienti",emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiTabella(valoreDiRitorno.risultatoRitorno);
                    setStato(STATO_TABELLA);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    function memorizza() {
        stato===STATO_INSERISCI?setStato(STATO_ATTESA2):setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:stato===STATO_INSERISCI?"clienti_ins":"clienti_mod",record:datiRecord,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    scaricaDatiTabella();
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    if (stato===STATO_ERRORE) return(<div></div>)
    if (stato===STATO_ATTESA) return(<div>attendi...</div>)
    if (stato===STATO_ATTESA2) return(<div>attendi, creazione dell'utente e del database in corso, richiede diversi secondi...</div>)

    if (stato===STATO_TABELLA) {
        return (            
            <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                <div>
                    <div className="titolo">Clienti: {datiTabella.length}<br/>
                    <span style={{fontSize:12}}>(compresi quelli che hanno solo fatto un ordine o sono in prova gratuita ma non sono ancora clienti attivi)</span><br/>
                    <Button className="bottoneStandard" onClick={() => { setDatiRecord([]); setStato(STATO_INSERISCI)}}>Nuovo</Button>
                    </div>
                    <div className="spazio1"/>
                </div>
                {datiTabella !== undefined && datiTabella !== "" ?
                    <TabellaRicerca 
                        columns={colonne} 
                        data={datiTabella} 
                        campoChiave={0} 
                        campoOrdinamento={1}
                        callbackSelezionaElemento={leggiRecordPerModifica}
                        callbackEliminaElemento={(id) => {
                            var r=[];
                            for (var n=0; n<datiTabella.length; n++) {
                                if (datiTabella[n].ID===id) {
                                    r=datiTabella[n];
                                    break;
                                }
                            }
                            setDatiRecord(r);
                            setStato(STATO_ELIMINA);
                        }} 
                    />
                : 
                    <div>Errore ricerca dati</div>
                }
            </div>
        )      
    }

    if (stato===STATO_ELIMINA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div>
                        <div className="titolo">Elimina Cliente</div>
                    </div>
                    <div style={{backgroundColor:"#FF0000", color: "#FFFF00", padding:"10px"}}>
                        Confermi eliminazione del cliente {datiRecord.RAGIONE_SOCIALE} (ID={datiRecord.ID})?                    
                        <br/><br/>
                        Scrivi ok qui sotto:
                        <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="text" onInput={(e)=>{setCampoConferma(e.target.value)}}/></div>
                    </div>
                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="bottoneAllarme" onClick={() => { if (campoConferma==="ok") {setCampoConferma(""); eliminaRecord(datiRecord.ID)}}}>Conferma eliminazione</Button>
                    &nbsp;
                    <Button className="bottoneStandard" onClick={() => { scaricaDatiTabella()}}>Annulla</Button>
                </div>
            </div> 
        )      
    }                    

    if (stato===STATO_INSERISCI || stato==STATO_MODIFICA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div>
                        <div className="titolo">{stato===STATO_INSERISCI?"Nuovo Cliente":"Modifica Cliente ID="+datiRecord.ID}</div>
                    </div>
                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Ragione Sociale</div>
                            <Input maxLength={250} className="inputStandard" type="text" defaultValue={datiRecord.RAGIONE_SOCIALE}
                            onInput={(e)=>{var r={...datiRecord}; r.RAGIONE_SOCIALE=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Email</div>
                            <Input maxLength={250} className="inputStandard" type="text" defaultValue={datiRecord.EMAIL}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.EMAIL=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div> 

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Cognome Referente</div>
                            <Input maxLength={250} className="inputStandard" type="text" defaultValue={datiRecord.COGNOME}
                            onInput={(e)=>{var r={...datiRecord}; r.COGNOME=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Nome Referente</div>
                            <Input maxLength={250} className="inputStandard" type="text" defaultValue={datiRecord.NOME}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.NOME=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div> 

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Partita IVA</div>
                            <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.PIVA}
                            onInput={(e)=>{var r={...datiRecord}; r.PIVA=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Codice Fiscale</div>
                            <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.CODFISCALE}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.CODFISCALE=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Telefono</div>
                            <Input maxLength={50} className="inputStandard" type="text" defaultValue={datiRecord.TELEFONO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.TELEFONO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div> 

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Indirizzo</div>
                            <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.INDIRIZZO}
                            onInput={(e)=>{var r={...datiRecord}; r.INDIRIZZO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div> 

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">CAP</div>
                            <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.CAP}
                            onInput={(e)=>{var r={...datiRecord}; r.CAP=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Città</div>
                            <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.CITTA}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.CITTA=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Stato (Nazione)</div>
                            <Input maxLength={50} className="inputStandard" type="text" defaultValue={datiRecord.STATO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.STATO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div>

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Codice Destinatario Fatt. Elettr.</div>
                            <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.CODICE_DESTINATARIO_FATT_ELETTRONICA}
                            onInput={(e)=>{var r={...datiRecord}; r.CODICE_DESTINATARIO_FATT_ELETTRONICA=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Data Chiusura Esercizio (AAAA-MM-GG)</div>
                            <Input maxLength={250} className="inputStandard" type="text" defaultValue={datiRecord.DATA_CHIUSURA_ESERCIZIO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.DATA_CHIUSURA_ESERCIZIO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna2">
                            <div className="etichetta">Note</div>
                            <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.NOTE}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.NOTE=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div>
                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="bottoneStandard" onClick={() => { memorizza()}}>Memorizza</Button>
                    &nbsp;
                    <Button className="bottoneStandard" onClick={() => { scaricaDatiTabella()}}>Torna all'elenco</Button>
                </div>
            </div> 
        )      
    }

    return (
        <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain, color:"#202020"}}>
            CLIENTI - stato errato: {stato}
        </div>
    )
}

export default Clienti;