function TrasformaDateAUmano(props) {
    let dataOutput='';
    //da formato aaaa-mm-gg a gg/mm/aaaa oppure date senza anno del tipo mm-gg le rigiro in gg/mm
    if(props.data){
      //console.log("Arriva"+props.data)
      //controllo il tipo di dato:
      if(props.data.length === 10){
        //console.log("Arriva"+props.data)
        const arrdata = props.data.split('-');
        let anno = arrdata[0];
        let mese = arrdata[1];
        let giorno = arrdata[2];

        dataOutput = giorno+"/"+mese+"/"+anno;
      }
      if(props.data.length === 5){
        const arrdata = props.data.split('-');
        let mese = arrdata[0];
        let giorno = arrdata[1];
        dataOutput = giorno+"/"+mese;
      }
      //console.log("RITORNO"+dataOutput)
      if(props.data.length !== 10 && props.data.length !== 5 ) return -1;

    }else dataOutput = '';

    return dataOutput;
 
}
export default TrasformaDateAUmano;
