import React, {useEffect, useState} from 'react';
import TabellaRicerca from './tabellaricerca';

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_TABELLA = 2;
const STATO_ERRORE = 6;
const STATO_ATTESA = 7;
const STATO_ATTESA2 = 8;

// nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
const colonne = [
    {
      etichetta: 'ID',
      accessor: 'ID'
    },
    {
      etichetta: 'Ragione Sociale',
      accessor: 'RAGIONE_SOCIALE'
    },
    {
      etichetta: 'Email',
      accessor: 'EMAIL'
    },
    {
        etichetta: 'Telefono',
        accessor: 'TELEFONO'
    },  
    {
      etichetta: 'P.IVA',
      accessor: 'PIVA'
    },
    {
      etichetta: 'Cod. Fiscale',
      accessor: 'CODFISCALE'
    },
    {
        etichetta: 'Indirizzo',
        accessor: 'INDIRIZZO'
    },
    {
        etichetta: 'CAP',
        accessor: 'CAP'
    },
    {
      etichetta: 'Città',
      accessor: 'CITTA'
    },
    {
      etichetta: 'Stato',
      accessor: 'STATO'
    },
    {
      etichetta: 'Cod.Dest.',
      accessor: 'CODICE_DESTINATARIO_FATT_ELETTRONICA'
    }
  ];

function ClientiElencoParziale(props) {    
    const [stato, setStato] = useState(STATO_INDEFINITO);
    const [datiTabella, setDatiTabella] = useState([]);

    useEffect(() => {
        setStato(STATO_RICERCA_DATI);
    },[])
    
    useEffect(() => {
        if (stato === STATO_RICERCA_DATI) scaricaDatiTabella();               
    },[stato])  

    function scaricaDatiTabella() {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:props.tipo,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiTabella(valoreDiRitorno.risultatoRitorno);
                    setStato(STATO_TABELLA);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    if (stato===STATO_ERRORE) return(<div></div>)
    if (stato===STATO_ATTESA) return(<div>attendi...</div>)
    if (stato===STATO_ATTESA2) return(<div>attendi, creazione dell'utente e del database in corso, richiede diversi secondi...</div>)

    if (stato===STATO_TABELLA) {
        return (            
            <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                <div>
                    <div className="titolo">Clienti 
                    {props.tipo==="clientiSenzaDb" && " senza database"}
                    {props.tipo==="clientiScaduti" && " con abbonamento scaduto"}
                    : {datiTabella.length}</div>
                    <div className="spazio1"/>
                </div>
                {datiTabella !== undefined && datiTabella !== "" ?
                    <TabellaRicerca 
                        columns={colonne} 
                        data={datiTabella} 
                        campoChiave={0} 
                        campoOrdinamento={1}
                    />
                : 
                    <div>Errore ricerca dati</div>
                }
            </div>
        )      
    }

    return (
        <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain, color:"#202020"}}>
            CLIENTI ELENCO PARZIALE - stato errato: {stato}
        </div>
    )
}

export default ClientiElencoParziale;