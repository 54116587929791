import React, {useEffect, useState} from 'react';
import TabellaRicerca from './tabellaricerca';

const STATO_INDEFINITO = 0; 
const STATO_RICERCA_DATI = 1;
const STATO_VISUALIZZA_ELENCO_DATABASE = 2;
const STATO_VISUALIZZA_ELENCO_TABELLE = 3;
const STATO_ERRORE = 6;
const STATO_ATTESA = 7;

// nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
const colonneElencoDatabase = [
    {
        etichetta: 'Database',
        accessor: 'db'
    },
    {
        etichetta: 'Dimensione [MB]',
        accessor: 'dimensione'
    },
    {
        etichetta: 'Spazio Disco [MB]',
        accessor: 'spazio'
    },
    {
        etichetta: 'Numero record',
        accessor: 'record'
    }
];
const colonneElencoTabelle = [
    {
        etichetta: 'Tabella',
        accessor: 'tabella'
    },
    {
        etichetta: 'Dimensione [MB]',
        accessor: 'dimensione'
    },
    {
        etichetta: 'Spazio Disco [MB]',
        accessor: 'spazio'
    },
    {
        etichetta: 'Numero record',
        accessor: 'record'
    }
];

function GestioneDb(props) {    
    const [stato, setStato] = useState(STATO_INDEFINITO);
    const [databaseSelezionato, setDatabaseSelezionato] = useState("");
    const [datiElencoDatabase, setDatiElencoDatabase] = useState([]);
    const [datiElencoTabelle, setDatiElencoTabelle] = useState([]);

    useEffect(() => {
        setStato(STATO_RICERCA_DATI);
        setDatabaseSelezionato("");
    },[])

    useEffect(() => {
        if (stato === STATO_RICERCA_DATI) scaricaDimensioniDatabase();               
    },[stato])  

    function callbackSelezionaDatabase(d) {
        setDatabaseSelezionato(d);
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"dimensioniTabelle",database:d,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiElencoTabelle(valoreDiRitorno.dati);                    
                    setStato(STATO_VISUALIZZA_ELENCO_TABELLE);
                }
                                     
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    function scaricaDimensioniDatabase() {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"dimensioniDatabase",emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiElencoDatabase(valoreDiRitorno.dati);                    
                    setStato(STATO_VISUALIZZA_ELENCO_DATABASE);
                }
                                     
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    if (stato===STATO_ERRORE) return(<div></div>)
    if (stato===STATO_ATTESA) return(<div>attendi...</div>)

    if (stato===STATO_VISUALIZZA_ELENCO_DATABASE) {
        return (            
            <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                <div>
                    <div className="titolo">Dimensione dei database<br/>
                    </div>
                    <div className="spazio1"/>
                    Clicca su un database per i dettagli delle singole tabelle
                    <div className="spazio1"/>
                </div>
                {datiElencoDatabase !== undefined && datiElencoDatabase !== "" ?
                    <TabellaRicerca 
                        columns={colonneElencoDatabase} 
                        data={datiElencoDatabase} 
                        campoChiave={0} 
                        campoOrdinamento={1}
                        ordinamentoDecrescente={1}
                        callbackSelezionaElemento={callbackSelezionaDatabase}
                    />
                : 
                    <div>Errore ricerca dati</div>
                }
            </div>
        )      
    }

    if (stato===STATO_VISUALIZZA_ELENCO_TABELLE) {
        return (            
            <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                <div>
                    <div className="titolo">Tabelle del database {databaseSelezionato}<br/>
                    </div>
                    <div className="spazio1"/>
                </div>
                {datiElencoDatabase !== undefined && datiElencoDatabase !== "" ?
                    <TabellaRicerca 
                        columns={colonneElencoTabelle} 
                        data={datiElencoTabelle} 
                        campoChiave={0} 
                        campoOrdinamento={1}
                        ordinamentoDecrescente={1}
                    />
                : 
                    <div>Errore ricerca dati</div>
                }
            </div>
        )      
    }    

    return (
        <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain, color:"#202020"}}>
            GESTIONEDB - stato errato: {stato}
        </div>
    )
}

export default GestioneDb;