import React, {useEffect, useState} from 'react';
import TabellaRicerca from './tabellaricerca';

const STATO_INDEFINITO = 0; 
const STATO_RICERCA_DATI = 1;
const STATO_TABELLA = 2;
const STATO_ERRORE = 6;
const STATO_ATTESA = 7;

// nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
const colonne = [
    {
      etichetta: 'Ragione Sociale',
      accessor: 'RAGIONE_SOCIALE'
    },
    {
      etichetta: 'Database',
      accessor: 'NOME_DATABASE'
    },
    {
        etichetta: 'Accessi Amm',
        accessor: 'AMMINISTRAZIONE'
    },  
    {
      etichetta: 'Accessi Staff',
      accessor: 'STAFF'
    },
    {
      etichetta: 'Accessi Iscritti',
      accessor: 'ALTRI'
    },
    {
        etichetta: 'Accessi Tot',
        accessor: 'TOTALI'
    },
    {
        etichetta: 'Primo accesso',
        accessor: 'PRIMO'
    },
    {
        etichetta: 'Ultimo accesso',
        accessor: 'ULTIMO'
    }
  ];

function Accessi(props) {    
    const [stato, setStato] = useState(STATO_INDEFINITO);
    const [datiTabella, setDatiTabella] = useState([]);

    useEffect(() => {
        setStato(STATO_RICERCA_DATI);
    },[])

    useEffect(() => {
        if (stato === STATO_RICERCA_DATI) scaricaDatiTabella();               
    },[stato])  

    function scaricaDatiTabella() {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"accessi",emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiTabella(valoreDiRitorno.accessi);                    
                    setStato(STATO_TABELLA);
                }
                                     
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    if (stato===STATO_ERRORE) return(<div></div>)
    if (stato===STATO_ATTESA) return(<div>attendi...</div>)

    if (stato===STATO_TABELLA) {
        return (            
            <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                <div>
                    <div className="titolo">Accessi<br/>
                    </div>
                    <div className="spazio1"/>
                </div>
                {datiTabella !== undefined && datiTabella !== "" ?
                    <TabellaRicerca 
                        columns={colonne} 
                        data={datiTabella} 
                        campoChiave={0} 
                        campoOrdinamento={5}
                    />
                : 
                    <div>Errore ricerca dati</div>
                }
            </div>
        )      
    }

    return (
        <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain, color:"#202020"}}>
            ACCESSI - stato errato: {stato}
        </div>
    )
}

export default Accessi;