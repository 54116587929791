
function Parametri(parametro) {
switch (parametro) {
case 'serverURL': return 'https://ema1.gestionesportiva.it/link_back_front_gestione.php'; break;
case 'release': return 'P'; break;
case 'versione': return '0.1.16'; break;
}
return "";
}

export default Parametri;
