import React, {useState, useEffect} from 'react';
import Menu from './Menu'
import AppMain from './AppMain'
import imgMenu from './immagini/menu1.png';
import imgCasa from './immagini/casa1.png';
import Parametri from './parametri.js';
import './App.css';

function App() {
  const [autenticatoFase1, setAutenticatoFase1] = useState(false);
  const [autenticato, setAutenticato] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [pagina, setPagina] = useState(0);
  const [dimensioniApp, setDimensioniApp] = useState({x: 0, y:0, yTitolo:0, yMain:0});
  const [flagMenuVisibile, setFlagMenuVisibile] = useState(true);
  const [flagMenuRidotto, setFlagMenuRidotto] = useState(false);
  const xMinimaPerMenuNonRidotto = 1000;
  const [chiudiTuttiSottomenu, setChiudiTuttiSottomenu] = useState(false);
  const [ricarica, setRicarica] = useState(false);  
  const emak = "w-HkwNcfPrGuxs0GI2CGPCGflKzg30otG3D+V8Aj0vDc80zmbKo1=.9OOEADH:,F.hXuAzvL|JS,GQ_1mXG3HN1i11GmQJEf?PJI";
  //const serverURL="https://massbroken.dyndns.org:2003/ema/link_back_front_gestione.php";
  //const serverURL="https://emabackend.massbroken.it/link_back_front_gestione.php";
  const serverURL=Parametri("serverURL");
  const titoloApp="GESTIONE EMA";

  const PAGINE = {
    "home": 0,
    "tornaLogin": 1,
    "clienti": 10,
    "clientiSenzaDb": 12,
    "clientiScaduti": 13,
    "provaGratis": 15,
    "richiesteAcquisti": 16,
    "articoli": 20,
    "ordini": 30,
    "pagamenti": 35,
    "accessi": 40,
    "reportUtenti": 50,
    "log": 60,
    "gestioneDb": 70,
    "cambioPassword": 80
  }

  useEffect(() => {
  
    const calcolaDimensioniApp = () => {      
      const x = document.getElementById("principale").clientWidth;
      const y = document.getElementById("principale").clientHeight;
      var yTitolo = y/20;
      if (yTitolo<40) yTitolo = 40;
      var yMain = y-yTitolo;;
      setDimensioniApp({x:x, y:y, yTitolo:yTitolo, yMain: yMain});
      setFlagMenuRidotto(x<xMinimaPerMenuNonRidotto);
    }
    
    calcolaDimensioniApp();
    window.addEventListener('resize', calcolaDimensioniApp);
    
    return () => {
     window.removeEventListener('resize', calcolaDimensioniApp);
    };    
  }, []);

  useEffect(() => {
    if (ricarica) {
      setRicarica(false)
    }
  },[ricarica])  

  useEffect(() => {
    if (pagina===PAGINE.tornaLogin) {
      setAutenticato(false)
    }
  },[pagina])  

  function estraiNomeDominio(url) {
    try {
      const urlObject = new URL(url);
      return urlObject.hostname;
    } catch (error) {
      console.error('URL non valida');
      return null;
    }
  }

  function inviaCredenzialiServer() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"login",
            login:login,
            password:password,
            otp:otp,
            emak:emak,
            sessionId: sessionId
        })
    };

    fetch(serverURL, requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            switch(valoreDiRitorno.risultatoOperazione){
              case -3: setAutenticatoFase1(true); setSessionId(valoreDiRitorno.session_id); break;
              case -2: alert("Utenza bloccata (richiede sblocco manuale da db)"); setOtp(""); setAutenticatoFase1(false); setAutenticato(false); break;
              case -1: alert("Credenziali errate"); setOtp(""); setAutenticatoFase1(false); setAutenticato(false); break;
              case 0: alert("ERRORE backend"); setOtp(""); setAutenticatoFase1(false); setAutenticato(false); break;
              case 1: setAutenticato(true); setSessionId(valoreDiRitorno.session_id); break;
              default: alert("ERRORE backend: " + valoreDiRitorno.risultatoOperazione); setOtp(""); setAutenticatoFase1(false); setAutenticato(false);
            }            
        },
        (error) => {alert("ERRORE backend: "+error);}
    );        
  }

  if (!autenticatoFase1) {
    return (
      <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#202020", overflow:"hidden", color:"#e0e0e0"}}>
        <div style={{border:"1px solid #50C0C0", backgroundColor:"#383840", width:"50vw", margin:"auto", marginTop:"20vh", textAlign:"center" }}>
          <div style={{backgroundColor:"#50C0C0", padding:"5px", marginBottom:"20px", color:"#007070", fontWeight:"bold"}}>{titoloApp}</div>
          Login:
          <div style={{height:"0.5em"}}/>
          <input 
            style={{backgroundColor:"#C0C0C0", fontSize:"1rem", color: "#0000ff"}} 
            type="text"
            value={login} 
            onChange={(e)=>{setLogin(e.target.value);}}
          />  
          <div style={{height:"1.5em"}}/>
          Password:
          <div style={{height:"0.5em"}}/>
          <input 
            style={{backgroundColor:"#C0C0C0", fontSize:"1rem", color: "#0000ff"}} 
            type="password" 
            value={password}
            onChange={(e)=>{setPassword(e.target.value);
          }}/>
          <div style={{height:"1.5em"}}/>
          <input
            style={{backgroundColor:"#909090", fontSize:"1rem", color: "#FFFFFF"}} 
            type="submit"
            onClick={() => inviaCredenzialiServer()}
            value="OK"
          />
          <div style={{height:"1.5em"}}/>
        </div>
      </div>
    );
  }

  if (!autenticato) {
    return (
      <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#202020", overflow:"hidden", color:"#e0e0e0"}}>
        <div style={{border:"1px solid #50C0C0", backgroundColor:"#383840", width:"50vw", margin:"auto", marginTop:"20vh", textAlign:"center" }}>
          <div style={{backgroundColor:"#50C0C0", padding:"5px", marginBottom:"20px", color:"#007070", fontWeight:"bold"}}>{titoloApp}</div>
          OTP:
          <div style={{height:"0.5em"}}/>
          <input 
            style={{backgroundColor:"#C0C0C0", fontSize:"1rem", color: "#0000ff"}} 
            type="text" 
            value={otp}
            onChange={(e)=>{setOtp(e.target.value);
          }}/>
          <div style={{height:"1.5em"}}/>
          <input
            style={{backgroundColor:"#909090", fontSize:"1rem", color: "#FFFFFF"}} 
            type="submit"
            onClick={() => inviaCredenzialiServer()}
            value="OK"
          />
          <div style={{height:"1.5em"}}/>
        </div>
      </div>
    );
  }  

  return (
    <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#202020", overflow:"hidden", color:"#e0e0e0"}}>
      <div style={{height:dimensioniApp.yTitolo, display:"flex", borderBottom: "1px solid #505050"}}>
        <div style={{backgroundColor:"#212a2f", cursor:"pointer"}} onClick={() => {setFlagMenuVisibile(!flagMenuVisibile) }}>
          <img src={imgMenu} height={dimensioniApp.yTitolo} width={dimensioniApp.yTitolo} alt=""/>
        </div>
        <div style={{backgroundColor:"#212a2f", cursor:"pointer"}} onClick={() => { setChiudiTuttiSottomenu(true);setPagina(PAGINE.home) }}>
          <img src={imgCasa} height={dimensioniApp.yTitolo} width={dimensioniApp.yTitolo} alt=""/>
        </div>
        <div style={{fontSize:dimensioniApp.yTitolo/2.8, backgroundColor: "#50C0C0", flex:1, textAlign:'center', display:'flex', flexDirection:"column"}}>
          <div style={{fontSize:dimensioniApp.yTitolo/3.7, marginTop:4, color:"#005050", fontWeight:"bold"}}>{titoloApp}</div>          
          <div style={{fontSize:dimensioniApp.yTitolo/2.4, marginTop:1, color:"#005050"}}>{"backend: " + estraiNomeDominio(Parametri("serverURL"))}</div>
        </div>
      </div>

      <div style={{display:'flex', flexDirection:'row'}}>
        <Menu 
          dimensioniApp={dimensioniApp}
          flagVisibile={flagMenuVisibile}
          xMinimaPerMenuNonRidotto={xMinimaPerMenuNonRidotto}          
          flagMenuRidotto={flagMenuRidotto}
          setFlagMenuRidotto={setFlagMenuRidotto}
          PAGINE={PAGINE}
          pagina={pagina}
          setPagina={setPagina}
          setRicarica={setRicarica}
          chiudiTuttiSottomenu={chiudiTuttiSottomenu}
          setChiudiTuttiSottomenu={setChiudiTuttiSottomenu}
        />
        <div style={{flex:1, overflow:"auto", height:dimensioniApp.yMain, display:"flex", flexDirection:"column", backgroundColor:"#E0E0E0", color:"#202020"}}>
        {ricarica ? <div></div> :
          <AppMain 
            dimensioniApp={dimensioniApp}
            serverURL={serverURL}
            emak={emak}
            PAGINE={PAGINE}
            pagina={pagina}
            setPagina={setPagina}
            sessionId={sessionId}
          />
        }
        </div>
      </div>

    </div>
  )

}

export default App;
