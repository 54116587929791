import React, {useEffect, useState} from 'react';
import { Button } from 'reactstrap';

const STATO_INDEFINITO = 0;
const STATO_VISUALIZZA = 1;
const STATO_ATTESA = 2;
const STATO_ERRORE = 3;

function Log(props) {
    const [stato, setStato] = useState(STATO_INDEFINITO);    
    const [annoMese, setAnnoMese] = useState("");
    const [dalGiorno, setDalGiorno] = useState(1);
    const [alGiorno, setAlGiorno] = useState(31);
    const [idcliente, setIdcliente] = useState(-1);
    const [idpersona, setIdpersona] = useState("");
    const [flagErrore, setFlagErrore] = useState(true);
    const [flagDebug, setFlagDebug] = useState(false);
    const [flagInfo, setFlagInfo] = useState(true);
    const [flagAltro, setFlagAltro] = useState(true);
    const [dati, setDati] = useState([]);
    const [elencoClienti, setElencoClienti] = useState([]);
    const [nomeFileLog, setNomeFileLog] = useState("");

    useEffect(() => {
        setStato(STATO_ATTESA);
    },[])

    const d = new Date();
    var anno = d.getFullYear();
    var mese = d.getMonth()+1;
    var elencoDate = [];
    for (var i=0; i<12; i++) {
        elencoDate.push(anno+"-"+(mese<10?"0":"")+mese);
        mese--;
        if (mese<1) {mese=12; anno--;}
    }

    var elencoGiorni=[];
    for (var g=1; g<=31; g++) elencoGiorni.push(g);

    function inviaRichiesta(){
        if (annoMese=="") return;
        setStato(STATO_ATTESA);        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                op:"log",
                annoMese:annoMese,
                idpersona:idpersona,
                idcliente:idcliente,
                tipo1:flagErrore?"Errore":"",
                tipo2:flagInfo?"Info":"",
                tipo3:flagDebug?"Debug":"",
                tipo4:flagAltro?"Altro":"",
                dalgiorno:dalGiorno,
                algiorno:alGiorno,
                emak:props.emak,
                sessionId:props.sessionId
            })
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDati(valoreDiRitorno.dati);
                    setNomeFileLog(valoreDiRitorno.nomeFileLog);
                    setStato(STATO_VISUALIZZA);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    function scaricaElencoClienti() {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elenco_clienti",emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setElencoClienti(valoreDiRitorno.risultatoRitorno);
                    setStato(STATO_VISUALIZZA);                        
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }          

    useEffect(() => {
        scaricaElencoClienti();
    },[])

    if (stato===STATO_ERRORE) return (<div></div>);
    if (stato===STATO_ATTESA) return (<div>attendi...</div>);
    if (stato===STATO_VISUALIZZA) return (
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            <div className="schedaSpazioCentrale">
                {nomeFileLog!="" ? <div>Log da {nomeFileLog}<br/></div> : "" }
                {dati.length>0 ?
                    <table>
                        <tr style={{fontWeight:"bold", fontSize:"0.8rem"}}>
                            <td>Giorno-Ora</td>
                            <td>Tipo</td>
                            <td>Idpers</td>
                            <td>Sezione</td>
                            <td>Messaggio Log</td>
                        </tr>

                    {dati.map((valore, indice) =>
                        <tr key={indice} style={{backgroundColor:valore.tipo==="ERRORE"?"#FFA0A0":valore.tipo==="INFO"?"#60E060":"#E0E0E0", fontSize:"0.8rem"}}>
                            <td>{valore.giorno}-{valore.ora}</td>
                            <td>{valore.tipo}</td>
                            <td>{valore.idpersona}</td>
                            <td>{valore.sezione}</td>
                            <td>{valore.messaggio}</td>
                        </tr>
                    )
                    }
                    </table>
                :
                    <div></div>
                }
            </div>

            <div className="schedaSpazioInferiore">
                <div style={{display:"flex", flexDirection:"row", paddingBottom:10}}>                    
                    <div style={{paddingRight:5}}>
                        <select className="inputStandard" value={annoMese} onChange={(e) => {setAnnoMese(e.target.value);}}>
                            <option></option>
                            {elencoDate.map((valore, indice)=><option key={indice} value={valore}>{valore}</option>)}
                        </select>
                    </div>

                    dal&nbsp;
                    <div style={{paddingRight:5}}>
                        <select className="inputStandard" value={dalGiorno} onChange={(e) => {setDalGiorno(e.target.value);}}>
                            {elencoGiorni.map((valore, indice)=><option key={indice} value={valore}>{valore}</option>)}
                        </select>
                    </div>

                    al&nbsp;
                    <div style={{paddingRight:5}}>
                        <select className="inputStandard" value={alGiorno} onChange={(e) => {setAlGiorno(e.target.value);}}>
                            {elencoGiorni.map((valore, indice)=><option key={indice} value={valore}>{valore}</option>)}
                        </select>
                    </div>

                    db&nbsp;
                    <div style={{paddingRight:10}}>
                        <select className="inputStandard" value={idcliente} onChange={(e) => {setIdcliente(e.target.value);}}>
                            <option value={-1}>non connesso a db</option>
                            {elencoClienti.map((valore, indice)=><option key={indice} value={valore.ID}>{valore.RAGIONE_SOCIALE} (EMA{valore.ID})</option>)}
                        </select>
                    </div>

                    idpersona&nbsp;
                    <div style={{paddingRight:10}}>
                        <input type='text' size={5} className="inputStandard" onInput={(e)=>{setIdpersona(e.target.value)}}/>
                    </div>
                    <div style={{paddingRight:10}}>
                        <input type='checkbox' checked={flagErrore} onChange={()=>setFlagErrore(!flagErrore)}/>ERRORE
                    </div>
                    <div style={{paddingRight:10}}>
                        <input type='checkbox' checked={flagInfo} onChange={()=>setFlagInfo(!flagInfo)}/>INFO
                    </div>
                    <div style={{paddingRight:10}}>
                        <input type='checkbox' checked={flagDebug} onChange={()=>setFlagDebug(!flagDebug)}/>DEBUG
                    </div>
                    <div style={{paddingRight:10}}>
                        <input type='checkbox' checked={flagAltro} onChange={()=>setFlagAltro(!flagAltro)}/>Altro
                    </div>

                </div>
                <Button className="bottoneStandard" onClick={() => { inviaRichiesta()}}>Scarica</Button>
            </div>
        </div> 
    )      

    return (<div>Log: stato errato</div>)
}                    

export default Log;