import React, {useEffect, useState} from 'react';
import { Button, Input } from 'reactstrap';
import TabellaRicerca from './tabellaricerca';

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_TABELLA = 2;
const STATO_MODIFICA = 3;
const STATO_INSERISCI = 4;
const STATO_ELIMINA = 5;
const STATO_ERRORE = 6;
const STATO_ATTESA = 7;

const colonne = [
    {
      etichetta: 'ID',
      accessor: 'ID'
    },
    {
      etichetta: 'Ragione Sociale',
      accessor: 'RAGIONE_SOCIALE'
    },
    {
        etichetta: 'Cons.',
        accessor: 'CONSEGNATO'
    },
    {
        etichetta: 'Num.Ord.',
        accessor: 'NUMEROORDINE'
    },
    {
        etichetta: 'Tipo',
        accessor: 'TIPOORDINE'
    },    
    {
      etichetta: 'Data Ord.',
      accessor: 'DATAORDINE'
    },
    {
        etichetta: 'Cod. Articolo',
        accessor: 'CODICE_ARTICOLO'
    },  
    {
      etichetta: 'Inizio Serv.',
      accessor: 'DATA_INIZIO_SERVIZIO'
    },
    {
      etichetta: 'Fine Serv.',
      accessor: 'DATA_FINE_SERVIZIO'
    },
    {
        etichetta: 'Iscritti',
        accessor: 'LIMITE_ISCRITTI'
    },
    {
        etichetta: 'MB',
        accessor: 'LIMITE_MB'
    },
    {
        etichetta: 'Durata',
        accessor: 'DURATA_VALORE'
    },
    {
        etichetta: '(udm)',
        accessor: 'DURATA_UNITA_MISURA'
    },
    {
        etichetta: 'Lordo',
        accessor: 'IMPORTO_LORDO'
    },
    {
        etichetta: 'IVA',
        accessor: 'IVA'
    },
    {
      etichetta: 'Netto',
      accessor: 'IMPORTO_NETTO'
    },
    {
      etichetta: 'Pagato',
      accessor: 'IMPORTO_PAGATO'
    },
    {
      etichetta: 'Pagamento',
      accessor: 'METODO_PAGAMENTO'
    }
  ];

function Pagamenti(props) {    
    const [stato, setStato] = useState(STATO_INDEFINITO);
    const [elencoArticoli, setElencoArticoli] = useState([]);
    const [elencoClienti, setElencoClienti] = useState([]);
    const [datiTabella, setDatiTabella] = useState([]);
    const [datiRecord, setDatiRecord] = useState([]);
    const [campoConferma, setCampoConferma] = useState("");
    const [dataPropostaInizioServizio, setDataPropostaInizioServizio] = useState("");
    const [dataPropostaFineServizio, setDataPropostaFineServizio] = useState("");

    useEffect(() => {
        setStato(STATO_RICERCA_DATI);
    },[])
    
    useEffect(() => {
        if (stato === STATO_RICERCA_DATI) scaricaDatiTabella();               
    },[stato])  

    function leggiRecordPerModifica(id) {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"ordini_leggi_record",ID:id,calcolaDateServizio:1,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiRecord(valoreDiRitorno.record[0]);
                    setDataPropostaInizioServizio(valoreDiRitorno.dataPropostaInizioServizio);
                    setDataPropostaFineServizio(valoreDiRitorno.dataPropostaFineServizio);
                    setStato(STATO_MODIFICA);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );                
    }

    function eliminaRecord(id) {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"ordini_canc",ID:id,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    scaricaDatiTabella();
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );        
    }

    function scaricaDatiTabella() {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elenco_ordini_non_consegnati",emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiTabella(valoreDiRitorno.risultatoRitorno);                    

                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({op:"elenco_clienti",emak:props.emak,sessionId:props.sessionId})
                    };
            
                    fetch(props.serverURL, requestOptions)
                        .then(risposta => risposta.json())
                        .then(
                        (valoreDiRitorno) => {
                            if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                            if(valoreDiRitorno.risultatoOperazione!==1){
                                alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                                setStato(STATO_ERRORE);
                                return;
                            }
                            if(valoreDiRitorno.risultatoOperazione===1){
                                setElencoClienti(valoreDiRitorno.risultatoRitorno);

                                const requestOptions = {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify({op:"elenco_articoli",emak:props.emak,sessionId:props.sessionId})
                                };
                        
                                fetch(props.serverURL, requestOptions)
                                    .then(risposta => risposta.json())
                                    .then(
                                    (valoreDiRitorno) => {
                                        if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                                        if(valoreDiRitorno.risultatoOperazione!==1){
                                            alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                                            setStato(STATO_ERRORE);
                                            return;
                                        }
                                        if(valoreDiRitorno.risultatoOperazione===1){
                                            setElencoArticoli(valoreDiRitorno.risultatoRitorno);
                                            setStato(STATO_TABELLA);
                                        }
                                    },
                                        (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
                                    );
                            }
                        },
                            (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
                        );
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    function memorizza() {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:stato===STATO_INSERISCI?"ordini_ins":"ordini_mod",record:datiRecord,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    scaricaDatiTabella();
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    if (stato===STATO_ERRORE) return(<div></div>)
    if (stato===STATO_ATTESA) return(<div>attendi...</div>)

    if (stato===STATO_TABELLA) {
        return (            
            <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                <div>
                    <div className="titolo">Ordini Non Consegnati: {datiTabella.length}</div>
                    <div className="spazio1"/>
                </div>
                {datiTabella !== undefined && datiTabella !== "" ?
                    <TabellaRicerca 
                        columns={colonne} 
                        data={datiTabella} 
                        campoChiave={0} 
                        campoOrdinamento={5}
                        callbackSelezionaElemento={leggiRecordPerModifica}
                        callbackEliminaElemento={(id) => {
                            var r=[];
                            for (var n=0; n<datiTabella.length; n++) {
                                if (datiTabella[n].ID===id) {
                                    r=datiTabella[n];
                                    break;
                                }
                            }
                            setDatiRecord(r);
                            setStato(STATO_ELIMINA);
                        }} 
                    />
                : 
                    <div>Errore ricerca dati</div>
                }
            </div>
        )      
    }

    if (stato===STATO_ELIMINA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div>
                        <div className="titolo">Elimina Ordine</div>
                    </div>
                    <div style={{backgroundColor:"#FF0000", color: "#FFFF00", padding:"10px"}}>
                        Confermi eliminazione dell'ordine {datiRecord.ID} di {datiRecord.RAGIONE_SOCIALE} del {datiRecord.DATAORDINE})?                    
                        <br/><br/>
                        Scrivi ok qui sotto:
                        <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="text" onInput={(e)=>{setCampoConferma(e.target.value)}}/></div>
                    </div>
                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="bottoneAllarme" onClick={() => { if (campoConferma==="ok") {setCampoConferma(""); eliminaRecord(datiRecord.ID)}}}>Conferma eliminazione</Button>
                    &nbsp;
                    <Button className="bottoneStandard" onClick={() => { scaricaDatiTabella()}}>Annulla</Button>
                </div>
            </div> 
        )      
    }                    

    if (stato==STATO_MODIFICA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div>
                        <div className="titolo">Pagamento Ordine ID={datiRecord.ID}</div>
                    </div>
                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">
                                <b>Cliente</b>: {elencoClienti.map((cliente, indice) => <span>{cliente.ID==datiRecord.IDCLIENTE && cliente.RAGIONE_SOCIALE}</span>)}
                            </div>
                            <div className="etichetta">
                                <b>Articoli</b>: {datiRecord.CODICE_ARTICOLO}
                            </div>
                            <div className="etichetta">
                                <b>Numero Ordine</b>: {datiRecord.NUMEROORDINE}
                            </div>
                            <div className="etichetta">
                                <b>Tipo di Ordine</b>: {datiRecord.TIPOORDINE}
                                {dataPropostaInizioServizio=="" && <span style={{backgroundColor:"#FF0000", color: "#FFFFFF", marginLeft:10, paddingLeft:10, paddingRight:10}}>SEMBRA SBAGLIATO PER QUESTO CLIENTE</span>}
                            </div>
                            <div className="etichetta">
                                <b>Data ordine</b>: {datiRecord.DATAORDINE}
                            </div>
                            <div className="etichetta">
                                <b>Durata</b>: {datiRecord.DURATA_VALORE} {datiRecord.DURATA_UNITA_MISURA}
                            </div>
                            <div className="etichetta">
                                <b>Limite Iscritti</b>: {datiRecord.LIMITE_ISCRITTI}
                            </div>
                            <div className="etichetta">
                                <b>Limite MB</b>: {datiRecord.LIMITE_MB}
                            </div>
                            <div className="etichetta">
                                <b>Importo</b>: {datiRecord.IMPORTO_LORDO} euro ({datiRecord.IMPORTO_NETTO} + {datiRecord.IVA} IVA)
                            </div>
                            <div className="etichetta">
                                <b>Limite Iscritti</b>: {datiRecord.LIMITE_ISCRITTI}
                            </div>
                            <div className="etichetta">
                                <b>Limite MB</b>: {datiRecord.LIMITE_MB}
                            </div>
                        </div>
                    </div>

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Consegnato</div>
                            <select className='inputSelect' value={datiRecord.CONSEGNATO}
                                    onChange={(e)=>{
                                        var r={...datiRecord}; 
                                        r.CONSEGNATO=e.target.value;            
                                        // se consegnato passa da NO a SI allora popola in automatico le date di inizio e fine servizio                             
                                        if (e.target.value==1) {
                                            r.DATA_INIZIO_SERVIZIO=dataPropostaInizioServizio;
                                            r.DATA_FINE_SERVIZIO=dataPropostaFineServizio;
                                        }
                                        setDatiRecord(r);
                                    }}
                            >
                                <option value='0'>NO</option>
                                <option value='1'>SI</option>
                            </select>
                        </div> 
                        <div className="colonna">                            
                            <div className="etichetta">Data inizio servizio (AAAA-MM-GG)</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.DATA_INIZIO_SERVIZIO} value={datiRecord.DATA_INIZIO_SERVIZIO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.DATA_INIZIO_SERVIZIO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Data fine servizio (AAAA-MM-GG)</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.DATA_FINE_SERVIZIO} value={datiRecord.DATA_FINE_SERVIZIO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.DATA_FINE_SERVIZIO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div> 

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Importo pagato</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.IMPORTO_PAGATO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.IMPORTO_PAGATO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Data pagamento (AAAA-MM-GG)</div>
                            <Input maxLength={20} className="inputStandard" type="text" defaultValue={datiRecord.DATA_PAGAMENTO}
                            onInput={(e)=>{var r={...datiRecord}; r.DATA_PAGAMENTO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Metodo pagamento</div>
                            <Input maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.METODO_PAGAMENTO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.METODO_PAGAMENTO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div>
                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Note</div>
                            <textarea rows={10} className="inputStandard" value={datiRecord.NOTE}
                            placeholder="" onChange={(e)=>{var r={...datiRecord}; r.NOTE=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div>
                    <br/>
                    <li><b>Consegnato = SI rende l'ordine utilizzabile dal cliente da Data Inizio Servizio a Data Fine Servizio, a prescindere dai pagamenti</b></li><br/>
                    Se ci sono più pagamenti per questo ordine (rate):<br/>
                    <li>Data Pagamento è la data dell'ultimo pagamento</li>
                    <li>Importo Pagato è la somma di tutti i pagamenti</li>
                    <li>Mettere i dati dei singoli pagamenti nelle note</li>

                    
                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="bottoneStandard" onClick={() => { memorizza()}}>Memorizza</Button>
                    &nbsp;
                    <Button className="bottoneStandard" onClick={() => { scaricaDatiTabella()}}>Torna all'elenco</Button>
                </div>
            </div> 
        )      
    }

    return (
        <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain, color:"#202020"}}>
            PAGAMENTI - stato errato: {stato}
        </div>
    )
}

export default Pagamenti;