import React, {useState, useEffect} from 'react';

function Menu(props) {
    const voci = [{
      "voce":"Clienti",
      "pagina":props.PAGINE.clienti
    },{
      "voce":"Richieste prova",
      "pagina":props.PAGINE.provaGratis
    },{
      "voce":"Richieste acquisti",
      "pagina":props.PAGINE.richiesteAcquisti
    },{
      "voce":"Articoli",
      "pagina":props.PAGINE.articoli
    },{
      "voce":"Ordini",
      "pagina":props.PAGINE.ordini
    },{
      "voce":"Pagamenti",
      "pagina":props.PAGINE.pagamenti
    },{
      "voce":"Report",      
      "sottomenu":[
        {"voce": "Accessi", "pagina":props.PAGINE.accessi},
        {"voce": "Utenti", "pagina":props.PAGINE.reportUtenti},
      ]
    },{
      "voce":"Log",
      "pagina":props.PAGINE.log
    },{
      "voce":"Gestione Db",
      "pagina":props.PAGINE.gestioneDb,
    },{
      "voce":"Impostazioni",
      "sottomenu":[
        {"voce": "Cambio Password", "pagina":props.PAGINE.cambioPassword},
      ]
    },{
      "voce":"Logout",
      "pagina":props.PAGINE.logout
    }];

    const sfondoVocePrincipale = "#212a2f";
    const sfondoVocePrincipaleAperta = "#996633";
    const bordoInferiorePrincipale = "#505050";
    const testoVocePrincipale = "#d0d0d0";
    const testoVocePrincipaleAperta = "#f0f0f0";

    const sfondoVoceSecondaria = "#505050";
    const bordoInferioreSecondario = "#707070";
    const testoVoceSecondaria = "#f0f0f0";

    const sfondoVoceSelezionata = "#FFA500";
    const testoVoceSelezionata = "#000000";

    const [aperto, setAperto] = useState([]);
    const [voceSelezionata, setVoceSelezionata] = useState({"i":-1,"j":-1});

    useEffect(() => {
      var fa = [];
      for (var n=0; n<voci.length; n++) fa.push(false);
      setAperto(fa);
    }, [])

    useEffect(() => {
      if (props.chiudiTuttiSottomenu) {
        props.setChiudiTuttiSottomenu(false);
        var fa = [];
        for (var n=0; n<voci.length; n++) fa.push(false);
        setAperto(fa);
      }
    }, [props.chiudiTuttiSottomenu])    
    
    if (!props.flagVisibile) return (<></>)

    return (
    
        <div 
          className="scrollorizzontale"
          onMouseEnter={() => { if (props.flagMenuRidotto) props.setFlagMenuRidotto(false) }}
          onMouseLeave={() => { if (props.dimensioniApp.x<props.xMinimaPerMenuNonRidotto)  props.setFlagMenuRidotto(true) }}
          style={{
            height:props.dimensioniApp.yMain, 
            minWidth: props.flagMenuRidotto ? props.dimensioniApp.yTitolo+5 : "15em", 
            maxWidth: props.flagMenuRidotto ? props.dimensioniApp.yTitolo+15 : 10000,
            backgroundColor:sfondoVocePrincipale,userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none"
          }}
        >
            {voci.map((x,i) => 
              <div key={i}>
                <div 
                  onClick={() => {
                    var fa = [...aperto]; 
                    fa[i]=!fa[i];
                    // la riga seguente chiude gli altri sottomenu aperti:
                    if(fa[i]) for(var n=0; n<fa.length; n++) if (n!=i) fa[n]=false;

                    if (x.pagina!==undefined) {
                      setVoceSelezionata({"i":i,"j":-1});
                        if (x.pagina===props.pagina) {
                          props.setRicarica(true)
                        } else {
                          props.setPagina(x.pagina)
                        }
                    }

                    setAperto(fa);

                  }}
                  style={{
                    backgroundColor: aperto[i] ? sfondoVocePrincipaleAperta : sfondoVocePrincipale,
                    color: aperto[i] ? testoVocePrincipaleAperta : testoVocePrincipale,
                    paddingTop:5,
                    paddingBottom:5,
                    paddingLeft:10,
                    paddingRight:10,
                    whiteSpace: "nowrap",
                    fontSize:"1em",
                    borderBottom: "1px solid " + bordoInferiorePrincipale,
                    cursor: "pointer",
                    display: "flex"
                  }}
                >
                  {x.icona!==undefined ? <div><img src={x.icona} height="16px" width="16px" alt=""/></div> : <></>}
                  {props.flagMenuRidotto ? "" : <div style={{paddingLeft:"7px"}}>{x.voce}</div>}
                </div>
                {aperto[i] && x.sottomenu ? 
                  x.sottomenu.map((y,j) =>
                    <div
                      key={j}
                      onClick={() => {
                        setVoceSelezionata({"i":i,"j":j});
                        if (y.pagina===props.pagina) {
                          props.setRicarica(true)
                        } else {
                          props.setPagina(y.pagina)
                        }
                      }}
                      style={{
                        backgroundColor: i===voceSelezionata.i && j===voceSelezionata.j ? sfondoVoceSelezionata : sfondoVoceSecondaria,
                        color: i===voceSelezionata.i && j===voceSelezionata.j ? testoVoceSelezionata : testoVoceSecondaria,
                        paddingTop:4,
                        paddingBottom:4,
                        paddingLeft:10,
                        paddingRight:10,
                        whiteSpace: "nowrap",
                        fontSize:"1em",
                        borderBottom: "1px solid " + bordoInferioreSecondario,
                        cursor: "pointer"
    
                      }}
                    >
                      {props.flagMenuRidotto ? y.voce.substring(0,2)+"..." : y.voce}
                    </div>
                  )
                :<div></div>}
              </div>
            )}

        </div>    
    );
}

export default Menu;
