import React from 'react';
import Home from './Home.js';
import Clienti from './Clienti.js';
import ClientiElencoParziale from './ClientiElencoParziale.js';
import GestisciRichieste from './GestisciRichieste.js';
import Ordini from './Ordini.js';
import Pagamenti from './Pagamenti.js';
import Articoli from './Articoli.js';
import Accessi from './Accessi.js';
import ReportUtenti from './ReportUtenti.js';
import Log from './Log.js';
import GestioneDb from './GestioneDb.js';
import CambioPassword from './CambioPassword.js';

function AppMain(props) {
    if(props.pagina === props.PAGINE.home) return(<Home {...props}/>);    
    if(props.pagina === props.PAGINE.clienti) return(<Clienti {...props}/>);
    if(props.pagina === props.PAGINE.clientiSenzaDb) return(<ClientiElencoParziale {...props} tipo="clientiSenzaDb"/>);
    if(props.pagina === props.PAGINE.clientiScaduti) return(<ClientiElencoParziale {...props} tipo="clientiScaduti"/>);
    if(props.pagina === props.PAGINE.provaGratis) return(<GestisciRichieste {...props} tipoRichieste="provaGratis"/>);
    if(props.pagina === props.PAGINE.richiesteAcquisti) return(<GestisciRichieste {...props} tipoRichieste="richiesteAcquisti"/>);
    if(props.pagina === props.PAGINE.ordini) return(<Ordini {...props}/>);  
    if(props.pagina === props.PAGINE.pagamenti) return(<Pagamenti {...props}/>);  
    if(props.pagina === props.PAGINE.articoli) return(<Articoli {...props}/>);  
    if(props.pagina === props.PAGINE.accessi) return(<Accessi {...props}/>);  
    if(props.pagina === props.PAGINE.reportUtenti) return(<ReportUtenti {...props}/>);  
    if(props.pagina === props.PAGINE.log) return(<Log {...props}/>);  
    if(props.pagina === props.PAGINE.gestioneDb) return(<GestioneDb {...props}/>);
    if(props.pagina === props.PAGINE.cambioPassword) return(<CambioPassword {...props}/>);
    return(<Home {...props}/>); 
}

export default AppMain;