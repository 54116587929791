import React, {useEffect, useState} from 'react';
import { Button, Input } from 'reactstrap';

const STATO_INS_DATI = 1;
const STATO_ATTESA = 2;
const STATO_ESITO = 3;
const STATO_ERRORE = 4;

function CambioPassword(props) {
    const [stato, setStato] = useState(STATO_INS_DATI);    
    const [vecchiaPassword, setVecchiaPassword] = useState("");
    const [nuovaPassword1, setNuovaPassword1] = useState("");
    const [nuovaPassword2, setNuovaPassword2] = useState("");
    const [esito, setEsito] = useState("");

    function inviaDati(){
        if (nuovaPassword1!=nuovaPassword2 || nuovaPassword1.length<8) return;
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"cambio_password",vecchia:vecchiaPassword,nuova:nuovaPassword1,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setEsito("Cambio password effettuato");
                    setStato(STATO_ESITO);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    if (stato===STATO_ERRORE) return (<div></div>);
    if (stato===STATO_ATTESA) return (<div>attendi...</div>);
    if (stato===STATO_ESITO) return (<div>{esito}</div>);
    if (stato===STATO_INS_DATI) return (
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            <div className="schedaSpazioCentrale">
                <div>
                    <div className="titolo">Cambio Password</div>
                </div>
                <br/><br/>
                Vecchia password:
                <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="password" onInput={(e)=>{setVecchiaPassword(e.target.value)}}/></div>
                <br/><br/>
                Nuova password:
                <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="password" onInput={(e)=>{setNuovaPassword1(e.target.value)}}/></div>
                <br/><br/>
                Nuova password (conferma):
                <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="password" onInput={(e)=>{setNuovaPassword2(e.target.value)}}/></div>
            </div>

            <div className="schedaSpazioInferiore">
                <Button className="bottoneAllarme" onClick={() => { inviaDati()}}>Conferma Cambio Password</Button>
            </div>
        </div> 
    )      

    return (<div>Cambio Password: stato errato</div>)
}                    

export default CambioPassword;