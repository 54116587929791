
function FormattaVincoloVideo(valore) {
    let valoreOutput='';
    //
    if(valore){
        if(valore==="1") valoreOutput = "Svincolato";
        if(valore==="2") valoreOutput = "Vincolo Provvisorio";
        if(valore==="3") valoreOutput = "Vincolo Definitivo";
    }else valoreOutput = '';

    return valoreOutput;
}

export default FormattaVincoloVideo;
