
function FormattaFlagVideo(valore) {
    let valoreOutput='';
    //
    if(valore){
        if(valore==="0") valoreOutput = "No";
        if(valore==="1") valoreOutput = "Si";
    }else valoreOutput = '';

    return valoreOutput;
}

export default FormattaFlagVideo;
