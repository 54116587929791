import React, {useEffect, useState} from 'react';
import { Button, Input } from 'reactstrap';
import TabellaRicerca from './tabellaricerca';

function Articoli(props) {   
    const STATO_INDEFINITO = 0;     
    const STATO_RICERCA_DATI = 1;
    const STATO_TABELLA = 2;
    const STATO_MODIFICA = 3;
    const STATO_INSERISCI = 4;
    const STATO_ELIMINA = 5;
    const STATO_ERRORE = 6;
    const STATO_ATTESA = 7;

    const [stato, setStato] = useState(STATO_INDEFINITO);
    const [datiTabella, setDatiTabella] = useState([]);
    const [datiRecord, setDatiRecord] = useState([]);
    const [campoConferma, setCampoConferma] = useState("");
    
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
        {
          etichetta: 'Codice Articolo',
          accessor: 'CODICE_ARTICOLO'
        },
        {
          etichetta: 'Descrizione',
          accessor: 'DESCRIZIONE'
        },
        {
          etichetta: 'Importo Lordo',
          accessor: 'IMPORTO_LORDO'
        },
        {
          etichetta: 'IVA',
          accessor: 'IVA'
        },
        {
          etichetta: 'Importo Netto',
          accessor: 'IMPORTO_NETTO'
        },  
        {
            etichetta: 'Durata abb.',
            accessor: 'DURATA_VALORE'
        },
        {
            etichetta: 'Durata (udm)',
            accessor: 'DURATA_UNITA_MISURA'
        },
        {
          etichetta: 'Limite Iscritti',
          accessor: 'LIMITE_ISCRITTI'
        },
        {
          etichetta: 'Limite MB',
          accessor: 'LIMITE_MB'
        },
        {
            etichetta: 'Acquistabile',
            accessor: 'ACQUISTABILE'
        },
        {
            etichetta: 'Ord.',
            accessor: 'ORDINAMENTO'
        },
      ];    

    useEffect(() => {
        setStato(STATO_RICERCA_DATI);
    },[])

    useEffect(() => {
        if (stato === STATO_RICERCA_DATI) scaricaDatiTabella();               
    },[stato])  

    function leggiRecordPerModifica(codiceArticolo) {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"articoli_leggi_record",CODICE_ARTICOLO:codiceArticolo,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiRecord(valoreDiRitorno.record[0]);
                    setStato(STATO_MODIFICA);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );                
    }

    function eliminaRecord(codiceArticolo) {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"articoli_canc",CODICE_ARTICOLO:codiceArticolo,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione==-1){
                    alert("Richiesta cancellazione di articolo che compare in ordini: operazione non consentita");
                    setStato(STATO_TABELLA);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    scaricaDatiTabella();
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );        
    }

    function scaricaDatiTabella() {
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elenco_articoli",emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiTabella(valoreDiRitorno.risultatoRitorno);
                    setStato(STATO_TABELLA);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    function memorizza() {
        if (datiRecord.CODICE_ARTICOLO=="" || datiRecord.CODICE_ARTICOLO==undefined) return;
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:stato===STATO_INSERISCI?"articoli_ins":"articoli_mod",record:datiRecord,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    scaricaDatiTabella();
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    if (stato===STATO_ERRORE) return(<div></div>)
    if (stato===STATO_ATTESA) return(<div>attendi...</div>)

    if (stato===STATO_TABELLA) {
        return (            
            <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                <div>
                    <div className="titolo">Articoli: {datiTabella.length}<br/>
                    <Button className="bottoneStandard" onClick={() => { setDatiRecord([]); setStato(STATO_INSERISCI)}}>Nuovo</Button>
                    </div>
                    <div className="spazio1"/>
                </div>
                {datiTabella !== undefined && datiTabella !== "" ?
                    <TabellaRicerca 
                        columns={colonne} 
                        data={datiTabella} 
                        campoChiave={0} 
                        campoOrdinamento={10}
                        callbackSelezionaElemento={leggiRecordPerModifica}
                        callbackEliminaElemento={(codiceArticolo) => {
                            var r=[];
                            for (var n=0; n<datiTabella.length; n++) {
                                if (datiTabella[n].CODICE_ARTICOLO===codiceArticolo) {
                                    r=datiTabella[n];
                                    break;
                                }
                            }
                            setDatiRecord(r);
                            setStato(STATO_ELIMINA);
                        }} 
                    />
                : 
                    <div>Errore ricerca dati</div>
                }
            </div>
        )      
    }

    if (stato===STATO_ELIMINA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div>
                        <div className="titolo">Elimina Articolo</div>
                    </div>
                    <div style={{backgroundColor:"#FF0000", color: "#FFFF00", padding:"10px"}}>
                        Confermi eliminazione dell'articolo {datiRecord.CODICE_ARTICOLO} ({datiRecord.DESCRIZIONE})?                    
                        <br/><br/>
                        Scrivi ok qui sotto:
                        <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="text" onInput={(e)=>{setCampoConferma(e.target.value)}}/></div>
                    </div>
                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="bottoneAllarme" onClick={() => { if (campoConferma==="ok") {setCampoConferma(""); eliminaRecord(datiRecord.CODICE_ARTICOLO)}}}>Conferma eliminazione</Button>
                    &nbsp;
                    <Button className="bottoneStandard" onClick={() => { scaricaDatiTabella()}}>Annulla</Button>
                </div>
            </div> 
        )      
    }                    

    if (stato===STATO_INSERISCI || stato==STATO_MODIFICA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div>
                        <div className="titolo">{stato===STATO_INSERISCI?"Nuovo Articolo":"Modifica Articolo "+datiRecord.CODICE_ARTICOLO}</div>
                    </div>                    
                    {stato===STATO_MODIFICA?"Sono modificabili solo ACQUISTABILE e ORDINAMENTO perché modificare altri valori potrebbe applicare a richieste pendenti di acquisto i valori nuovi, mentre il cliente ha fatto la richiesta con valori vecchi. Per cambiare l'offerta, mettere ACQUISTABILE=no e generare un nuovo articolo con la nuova offerta.":""}
                    <div className="riga">  
                        {stato===STATO_INSERISCI?
                            <div className="colonna">
                                <div className="etichetta">Codice Articolo</div>
                                <Input readOnly={stato===STATO_MODIFICA} maxLength={250} className="inputStandard" type="text" defaultValue={datiRecord.CODICE_ARTICOLO}
                                placeholder="" onInput={(e)=>{var r={...datiRecord}; r.CODICE_ARTICOLO=e.target.value; setDatiRecord(r);}}/> 
                            </div>
                        :
                            <></>
                        }                    
                        <div className="colonna2">
                            <div className="etichetta">Descrizione</div>
                            <Input readOnly={stato===STATO_MODIFICA} maxLength={250} className="inputStandard" type="text" defaultValue={datiRecord.DESCRIZIONE}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.DESCRIZIONE=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div> 

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Importo Lordo</div>
                            <Input readOnly={stato===STATO_MODIFICA} maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.IMPORTO_LORDO}
                            onInput={(e)=>{var r={...datiRecord}; r.IMPORTO_LORDO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">IVA</div>
                            <Input readOnly={stato===STATO_MODIFICA} maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.IVA}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.IVA=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Importo Netto</div>
                            <Input readOnly={stato===STATO_MODIFICA} maxLength={50} className="inputStandard" type="text" defaultValue={datiRecord.IMPORTO_NETTO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.IMPORTO_NETTO=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Acquistabile</div>
                            <select className='inputSelect' value={datiRecord.ACQUISTABILE}
                            onChange={(e)=>{var r={...datiRecord}; r.ACQUISTABILE=e.target.value; setDatiRecord(r);}}
                            >
                                <option value='0'>0: no</option>
                                <option value='1'>1: dal cliente, nuovo</option>
                                <option value='2'>2: dal cliente, rinnovo</option>
                                <option value='3'>3: dal cliente, pacchetto aggiuntivo</option>
                                <option value='4'>4: solo da admin, nuovo</option>
                                <option value='5'>5: solo da admin, rinnovo</option>
                                <option value='6'>6: solo da admin, pacchetto aggiuntivo</option>
                            </select>
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Ordinamento</div>
                            <Input maxLength={50} className="inputStandard" type="text" defaultValue={datiRecord.ORDINAMENTO}
                            placeholder="" onInput={(e)=>{var r={...datiRecord}; r.ORDINAMENTO=e.target.value; setDatiRecord(r);}}/> 
                        </div>

                    </div> 

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Durata (non consentita maggiore di 31)</div>
                            <div style={{display:"flex", flexDirection:'row'}}>
                                <div style={{flex:1, paddingRight:20}}>                                
                                    <Input readOnly={stato===STATO_MODIFICA} maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.DURATA_VALORE}
                                    onInput={(e)=>{var r={...datiRecord}; r.DURATA_VALORE=e.target.value; setDatiRecord(r);}}/> 
                                </div>
                                <div style={{flex:1}}>
                                    <select disabled={stato===STATO_MODIFICA} className='inputSelect' value={datiRecord.DURATA_UNITA_MISURA}
                                    onChange={(e)=>{var r={...datiRecord}; r.DURATA_UNITA_MISURA=e.target.value; setDatiRecord(r);}}
                                    >
                                        <option value='anni'>anni</option>                                        
                                        <option value='mesi'>mesi</option>
                                        <option value='giorni'>giorni</option>
                                    </select>
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div className="riga">  
                        <div className="colonna">
                            <div className="etichetta">Limite Iscritti</div>
                            <Input readOnly={stato===STATO_MODIFICA} maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.LIMITE_ISCRITTI}
                            onInput={(e)=>{var r={...datiRecord}; r.LIMITE_ISCRITTI=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                        <div className="colonna">
                            <div className="etichetta">Limite MB</div>
                            <Input readOnly={stato===STATO_MODIFICA} maxLength={30} className="inputStandard" type="text" defaultValue={datiRecord.LIMITE_MB}
                            onInput={(e)=>{var r={...datiRecord}; r.LIMITE_MB=e.target.value; setDatiRecord(r);}}/> 
                        </div>
                    </div>
                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="bottoneStandard" onClick={() => { memorizza()}}>Memorizza</Button>
                    &nbsp;
                    <Button className="bottoneStandard" onClick={() => { scaricaDatiTabella()}}>Torna all'elenco</Button>
                </div>
            </div> 
        )      
    }

    return (
        <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain, color:"#202020"}}>
            ARTICOLI - stato errato: {stato}
        </div>
    )
}

export default Articoli;