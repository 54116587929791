import React, {useEffect, useState} from 'react';

function Home(props) {  

    const STATO_INDEFINITO = 0;
    const STATO_ATTESA = 1;
    const STATO_ERRORE = 2;
    const STATO_RICERCA_DATI = 3;
    const STATO_TABELLA = 4;
    const STATO_TEST = 5;

    const [stato, setStato] = useState(STATO_INDEFINITO);
    const [dati, setDati] = useState([]);

    useEffect(() => {
        setStato(STATO_RICERCA_DATI);
    },[])

    useEffect(() => {
        if (stato === STATO_RICERCA_DATI) {
            scaricaDatiTabella();               
        }
    },[stato])  

    function scaricaDatiTabella() {    
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"home",emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDati(valoreDiRitorno.risultatoRitorno);
                    setStato(STATO_TABELLA);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    if (stato===STATO_RICERCA_DATI) return(<div></div>)
    if (stato===STATO_ERRORE) return(<div></div>)
    if (stato===STATO_ATTESA) return(<div>attendi...</div>)

    if (stato===STATO_TABELLA) {
        var coloreSfondoRosso = "#FF7070";
        var coloreSfondoVerde = "#90FF90";
        var coloreSfondoGiallo = "#FFFF90"
        var coloreSfondoGrigio = "#B0B0B0";;
        return (
            <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain}}>
                <div style={{fontSize:22, fontWeight:'bold', marginTop:20, marginLeft:5}}>Richieste</div>
                <table style={{marginTop:5, marginLeft:5}}>
                    <tr>
                        <td style={{width:"20%",padding:5,textAlign:'center'}}></td>
                        <td style={{border: "1px solid #909090",width:"20%",padding:5,textAlign:'center'}}>Pendenti</td>
                        <td style={{border: "1px solid #909090",width:"20%",padding:5,textAlign:'center'}}>Prenotate</td>
                        <td style={{border: "1px solid #909090",width:"20%",padding:5,textAlign:'center'}}>Approvate</td>
                        <td style={{border: "1px solid #909090",width:"20%",padding:5,textAlign:'center'}}>Rifiutate</td>
                    </tr>
                    <tr>
                        <td style={{border: "1px solid #909090",textAlign:'center'}}>Prova Gratis</td>
                        <td style={{border: "1px solid #909090",backgroundColor:dati.richiesteProvaPendenti>0?coloreSfondoGiallo:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.richiesteProvaPendenti}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:dati.richiesteProvaPendentiPrenotate>0?coloreSfondoRosso:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.richiesteProvaPendentiPrenotate}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.richiesteProvaApprovate}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:coloreSfondoGrigio,padding:5,textAlign:'center'}}>{dati.richiesteProvaRifiutate}</td>
                    </tr>
                    <tr>
                        <td style={{border: "1px solid #909090",textAlign:'center'}}>Acquisto</td>
                        <td style={{border: "1px solid #909090",backgroundColor:dati.richiesteAcquistoPendenti>0?coloreSfondoGiallo:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.richiesteAcquistoPendenti}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:dati.richiesteAcquistoPendentiPrenotate>0?coloreSfondoRosso:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.richiesteAcquistoPendentiPrenotate}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.richiesteAcquistoApprovate}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:coloreSfondoGrigio,padding:5,textAlign:'center'}}>{dati.richiesteAcquistoRifiutate}</td>
                    </tr>
                </table>

                <div style={{fontSize:22, fontWeight:'bold', marginTop:20, marginLeft:5}}>Clienti</div>                
                <table style={{marginTop:5, marginLeft:5}}>
                    <tr>
                        <td style={{border: "1px solid #909090",width:"20%",padding:5,textAlign:'center'}}>Totali</td>
                        <td style={{border: "1px solid #909090",width:"16%",padding:5,textAlign:'center'}}>Attivi</td>
                        <td style={{border: "1px solid #909090",width:"16%",padding:5,textAlign:'center'}}>Paganti</td>
                        <td style={{border: "1px solid #909090",width:"16%",padding:5,textAlign:'center'}}>Non attivi</td>
                        <td onClick={() => props.setPagina(props.PAGINE.clientiScaduti)} style={{cursor:'pointer', backgroundColor:"#3366BB", textDecoration:'underline', color:'#FFFFFF', border: "1px solid #909090",width:"16%",padding:5,textAlign:'center'}}>Scaduti</td>
                        <td onClick={() => props.setPagina(props.PAGINE.clientiSenzaDb)} style={{cursor:'pointer', backgroundColor:"#3366BB", textDecoration:'underline', color:'#FFFFFF', border: "1px solid #909090",width:"16%",padding:5,textAlign:'center'}}>Senza db</td>
                    </tr>
                    <tr>
                        <td style={{border: "1px solid #909090",backgroundColor:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.clienti}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.attivi}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.paganti}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:dati.clienti-dati.attivi>0?coloreSfondoGiallo:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.clienti-dati.attivi}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:dati.scaduti>0?coloreSfondoRosso:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.scaduti}</td>
                        <td style={{border: "1px solid #909090",backgroundColor:dati.senzaDatabase>0?coloreSfondoGiallo:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.senzaDatabase}</td>
                    </tr>
                </table>
                <div style={{padding:5}}>
                    Attivi = hanno almeno un ordine consegnato<br/>(prova gratis è un ordine con importo zero)<br/><br/>
                    Scaduti = hanno abbonamenti scaduti e hanno un database, quindi possono accedere
                </div>
                <div style={{fontSize:22, fontWeight:'bold', marginTop:20, marginLeft:5}}>Ordini non consegnati</div>
                <table style={{marginTop:5, marginLeft:5}}>
                    <tr>
                        <td style={{border: "1px solid #909090",backgroundColor:dati.ordiniNonConsegnati>0?coloreSfondoGiallo:coloreSfondoVerde,padding:5,textAlign:'center'}}>{dati.ordiniNonConsegnati}</td>
                    </tr>
                </table>       
                <div onClick={() => setStato(STATO_TEST)}>TEST</div>         
            </div>
        )
    }

    return (
        <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain, color:"#202020"}}>
            HOME - stato errato: {stato}
        </div>
    )
}

export default Home;