import React, {useEffect, useState} from 'react';

const STATO_INDEFINITO = 0;
const STATO_ELENCO = 1;
const STATO_ATTESA = 2;
const STATO_ERRORE = 3;
const STATO_SELEZIONATA = 4;

function GestisciRichieste(props) {
    const [stato, setStato] = useState(STATO_INDEFINITO);    
    const [elencoRichieste, setElencoRichieste] = useState([]);
    const [richiestaSelezionata, setRichiestaSelezionata] = useState(-1);
    const [approva, setApprova] = useState(false);
    const [rifiuta, setRifiuta] = useState(false);

    useEffect(() => {
        setStato(STATO_ATTESA);
    },[])

    useEffect(() => {
        ottieniElencoRichieste();
    },[props.tipoRichieste])

    function decodificaBase64(base64String) {
        try {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
            let result = '';
            let i = 0;
            while (i < base64String.length) {
                const char1 = characters.indexOf(base64String.charAt(i++));
                const char2 = characters.indexOf(base64String.charAt(i++));
                const char3 = characters.indexOf(base64String.charAt(i++));
                const char4 = characters.indexOf(base64String.charAt(i++));
        
                const byte1 = (char1 << 2) | (char2 >> 4);
                const byte2 = ((char2 & 15) << 4) | (char3 >> 2);
                const byte3 = ((char3 & 3) << 6) | char4;
        
                result += String.fromCharCode(byte1);
        
                if (char3 !== 64) {
                    result += String.fromCharCode(byte2);
                }
                if (char4 !== 64) {
                    result += String.fromCharCode(byte3);
                }
            }    
            return result;
        } catch (error) {
            console.error('Errore durante la decodifica Base64:', error);
        }
    };

    function ottieniElencoRichieste(){
        setStato(STATO_ATTESA);
        var op="";
        if (props.tipoRichieste=="provaGratis") op="provaGratisElencoRichieste";
        if (props.tipoRichieste=="richiesteAcquisti") op="richiesteAcquistiElencoRichieste";
        if (op==="") {
            alert("Errore elenco richieste: tipoRichieste non valido ("+props.tipoRichieste+")")
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:op,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setElencoRichieste(valoreDiRitorno.elencoRichieste);
                    setStato(STATO_ELENCO);
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }

    function trasmettiDecisione(){
        var decisione = "";
        if (approva) decisione = "approva";
        if (rifiuta) decisione = "rifiuta";
        if (decisione=="") return;
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:props.tipoRichieste=="provaGratis"?"provaGratisDecisione":"richiesteAcquistiDecisione",idRichiesta:elencoRichieste[richiestaSelezionata].idRichiesta,decisione:decisione,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(props.serverURL, requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione===-1) {
                    alert("OPERAZIONE FALLITA: LA RICHIESTA NON ERA PIU' TRA QUELLE PENDENTI");
                    ottieniElencoRichieste();
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===-2) {
                    alert("OPERAZIONE FALLITA: trovati più clienti con stessa P.IVA o CODFISCALE");
                    ottieniElencoRichieste();
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===-3) {
                    alert("OPERAZIONE FALLITA: profilo non acquistabile da questo cliente, modificare manualmente il profilo (la richiesta resta pendente)");
                    ottieniElencoRichieste();
                    return;
                }

                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Errore backend, risultato operazione = "+valoreDiRitorno.risultatoOperazione);
                    setStato(STATO_ERRORE);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    ottieniElencoRichieste();
                }
            },
                (error) => {alert("Errore backend: "+error); setStato(STATO_ERRORE)}
            );
    }    

    if (stato===STATO_ERRORE) return (<div></div>);
    if (stato===STATO_ATTESA) return (<div>attendi...</div>);
    if (stato===STATO_ELENCO) return (
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            <div className="schedaSpazioCentrale">
                <div>
                    <div className="titolo">
                        Richieste&nbsp;
                        {props.tipoRichieste=="provaGratis"?"prova":"acquisto"}
                        &nbsp;pendenti: {elencoRichieste.length}</div>
                </div>
                {elencoRichieste.map((elemento,indice) => 
                    <div key={indice}>
                        <div style={{display:"flex", flexDirection:"column", border: "0.1px solid black", marginBottom:5}}>
                            <div style={{fontWeight:'bold', paddingLeft:2, paddingRight:2, fontSize:10, backgroundColor:"#D0D0D0", borderBottom: "0.1px solid black"}}>
                                FILE: {elemento.idRichiesta}
                            </div>
                            <div 
                                style={{flex:1, paddingLeft:10, cursor:"pointer"}} 
                                onClick={() => {setRichiestaSelezionata(indice); setStato(STATO_SELEZIONATA); setApprova(false); setRifiuta(false)}}
                            >
                                <pre>{decodificaBase64(elemento.richiesta)}</pre>
                            </div>
                        </div>
                    </div>
                )}

            </div>

        </div> 
    )      

    if (stato===STATO_SELEZIONATA) return (
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            <div className="schedaSpazioCentrale">
                <div>
                    <div className="titolo">Richiesta selezionata: n.{richiestaSelezionata+1} di {elencoRichieste.length}</div>
                </div>
                <div style={{display:"flex", flexDirection:"row", border: "0.1px solid black"}}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <div style={{fontWeight:'bold', paddingLeft:2, paddingRight:2, fontSize:10, borderBottom: "0.1px solid black"}}>{elencoRichieste[richiestaSelezionata].idRichiesta}</div>
                        <div style={{padding:15}}>
                            <input 
                                type='checkbox' 
                                checked={approva}
                                onChange={(e) => {setApprova(e.target.checked); if (e.target.checked) setRifiuta(false)}}
                            />
                            &nbsp;&nbsp;APPROVA
                        </div>
                        <div style={{padding:15}}>
                        <input 
                                type='checkbox' 
                                checked={rifiuta}
                                onChange={(e) => {setRifiuta(e.target.checked); if (e.target.checked) setApprova(false)}}
                            />
                            &nbsp;&nbsp;RIFIUTA
                        </div>                                
                        <div 
                            style={{
                                flex:1,
                                backgroundColor:approva?"#A0F0A0":rifiuta?"#F0A0A0":"#A0A0A0",
                                textAlign:'center',
                                display:"flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                borderTop: "0.1px solid black",
                                cursor: "pointer"
                            }}
                            onClick={() => trasmettiDecisione()}
                        >        
                            OK
                        </div>
                    </div>
                    <div style={{flex:1, display:"flex", flexDirection:"row", paddingLeft:10, borderLeft: "0.1px solid black"}}>
                        <pre>{decodificaBase64(elencoRichieste[richiestaSelezionata].richiesta)}</pre>
                    </div>
                </div>                
            </div>
        </div> 
    )          

    return (<div>GestisciRichieste: stato errato</div>)
}                    

export default GestisciRichieste;